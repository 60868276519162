<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <feather-icon
            icon="ClockIcon"
            size="48"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            Please wait...
          </p>
        </div>
      </template>
      <b-row v-if="reportNotFound">
        <b-col>
          <b-alert
            :show="reportNotFound"
            variant="danger"
            class="mb-50"
          >
            <div class="alert-body">
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>Report not found. Click <router-link :to="{ name: 'reports' }">here</router-link> to view reports.</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row
        v-else
        class="match-height"
      >
        <b-col
          v-if="$can('submit_feedback', 'Report')"
          lg="8"
        >
          <b-card title="Report Preview">
            <b-card-body>
              <div
                class="iframe-container"
                style="--aspect-ratio: 16/9; min-height:800px"
              >
                <iframe
                  id="frame"
                  :src="reportURL"
                  width="1600"
                  height="900"
                  frameborder="0"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-else
        >
          <b-card title="Report Preview">
            <b-card-body>
              <div
                class="iframe-container"
                style="--aspect-ratio: 16/9; min-height:800px"
              >
                <iframe
                  id="frame"
                  :src="reportURL"
                  width="1600"
                  height="900"
                  frameborder="0"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="4"
        >
          <b-card>
            <b-card-body>
              <div
                v-if="$can('submit_feedback', 'Report')"
                class="feedback-ctrl"
              >
                <h3>Consultant Feedback</h3>
                <b-form @submit.prevent="saveFeedback">
                  <b-form-group>
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="feedback"
                    />
                  </b-form-group>
                  <b-form-group>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      :disabled="loading"
                      class="mt-2 mr-1"
                    >
                      <b-spinner
                        v-show="loading"
                        small
                      />
                      <span class="m-1">{{ $t('buttons.save_changes') }}</span>
                    </b-button>
                  </b-form-group>
                </b-form>
              </div>
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                  type="reset"
                  class="mt-2 mr-1"
                  @click.prevent="downloadReport"
                >
                  Download PDF
                </b-button>
                <b-button
                  v-if="$can('delete', getReportSubject())"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mt-2"
                  @click="deleteGeneratedReport"
                >
                  <span class="m-1">{{ $t('buttons.delete_report') }}</span>
                </b-button>
              </b-form-group>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BCard, BCardBody, BForm, BFormGroup, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { subject } from '@casl/ability'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardBody,
    quillEditor,
    BForm,
    BFormGroup,
    BButton,
    BOverlay,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reportNotFound: false,
      reportURL: null,
      feedbackText: null,
      content: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('report', ['report']),
    ...mapGetters('auth', ['activeUser']),
    feedback: {
      // getter
      get() {
        return this.feedbackText
      },
      // setter
      set(newValue) {
        this.feedbackText = newValue
      },
    },
  },
  async created() {
    this.getReport(this.$route.params.reportUuid).then(() => {
      if (this.activeUser.currentRole.name === 'admin' || this.activeUser.currentRole.name === 'dentist') {
        this.reportURL = `${process.env.VUE_APP_API_HOST}/api/reports/${this.$route.params.reportUuid}?format=html`
      } else {
        this.reportURL = `${process.env.VUE_APP_API_HOST}/api/reports/${this.$route.params.reportUuid}?format=html&hide_responses=true`
      }
      this.feedbackText = this.report.feedback
    })
      .catch(() => {
        this.reportNotFound = true
      })
  },
  methods: {
    ...mapActions('report', ['getReport', 'updateReport', 'fetchPdfReport', 'deleteReport']),
    async downloadReport() {
      this.loading = true
      try {
        const filename = `dentyme_${this.report.report_type.name.toLowerCase()}_report_${moment(this.report.start_date).format('L')}-${moment(this.report.end_date).format('L')}_${this.report.uuid}.pdf`
        const pdfResponse = await this.fetchPdfReport(this.report.uuid)
        const blob = new Blob([pdfResponse.data], {
          type: pdfResponse.headers['content-type'],
        })
        const url = window.URL.createObjectURL(blob)
        // const filename = (response.headers['content-disposition'] || '').split('filename=')[0];
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      } catch (e) {
        console.debug(`Error downloading PDF of Report: ${e.message}`)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong when trying to download the Report PDF. Please try again.',
          },
        }, 5000)
      } finally {
        this.loading = false
      }
    },
    getReportSubject() {
      const reportSubject = subject('Report', {
        ...this.report,
      })
      return reportSubject
    },
    async saveFeedback() {
      try {
        this.loading = true
        const payload = {}
        payload.uuid = this.report.uuid
        payload.feedback = this.feedback
        if (this.feedback === '') {
          payload.feedback = null
        }
        await this.updateReport(payload)

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Feedback was submitted successfully',
          },
        }, 5000)
        // reload iframe
        document.getElementById('frame').src += ''
        this.loading = false
      } catch (e) {
        console.debug(`Error saving report feedback: ${e.message}`)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong when trying to submit your feedback. Please try again.',
          },
        }, 5000)
      }
    },
    async deleteGeneratedReport() {
      try {
        this.$swal({
          title: 'Delete Report',
          text: 'Please confirm you would like to delete this generated report.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete report',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            // eslint-disable-next-line no-useless-catch
            try {
              await this.deleteReport(this.report.uuid)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Report was deleted successfully',
                },
              }, 5000)
              this.$router.push({ path: '/reports', replace: true })
            } catch (e) {
              throw e
            }
          }
        }).catch(e => {
          throw e
        })
      } catch (e) {
        console.debug(`Error in deleteGeneratedReport: ${e.message}`)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong when trying to delete the report. Please try again.',
          },
        }, 5000)
      }
    },
  },
  metaInfo() {
    return { title: this.$t('page_titles.view_report'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>

<style lang="scss">
.ql-editor {
    min-height: 200px;
  }
  /*! rtl:begin:ignore */
  #dashboard-analytics {
    .greet-user {
      position: relative;

      .decore-left {
        position: absolute;
        left: 0;
        top: 0;
      }

      .decore-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    @media(max-width: 576px) {

      .decore-left,
      .decore-right {
        width: 140px;
      }
    }
  }

  .apexcharts-legend-marker {
    height: 1.2em !important;
    width: 1.2em !important;
  }

  .apexcharts-legend-text {
    font-size: 1.2em !important;
  }

  /*! rtl:end:ignore */
  #frame {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  [style*="--aspect-ratio"]> :first-child {
    width: 100%;
  }

  [style*="--aspect-ratio"]>img {
    height: auto;
  }

  @supports (--custom:property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }

    [style*="--aspect-ratio"]::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }

    [style*="--aspect-ratio"]> :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      overflow-x: hidden;
    }
  }
</style>
